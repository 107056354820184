body {
   padding-top: 60px
}

.text {
   max-width: 25em;
   margin-left: auto;
   margin-right: auto;
}

a {
   &:link {
      color: #7F7F7F;
   }
   
   &:visited {
      color: #7F7F7F;
   }
   
   &:hover {
      color: #383838
   }
}

footer {
   font-size: 90%;
   color: #666;
   border-top: 2px solid #eee;
   margin-top: 2em;
   margin-bottom: 4em;
   overflow: hidden;

   .contact {
     float: right;
   }
}

.circle {
   border-radius: 50%;
   display: inline-block;
   width: 100px;
   height: 100px;
   float:left;
   margin-right: 5px;

   /* tranparency */
   zoom: 1;
   filter: alpha(opacity=20);
   opacity: 0.2;

   &:hover {
      filter: alpha(opacity=90);
      opacity: 0.9;
   }
}


@mixin background-size {
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}

#github-circle {
   background: url(/images/GitHub-Mark-120px-plus.png);
   @include background-size;
}

#linkedin-circle {
   background: url(/images/LinkedIn-InBug-2C.png);
   @include background-size;
}

#email-circle {
   background: url(/images/email.png);
   @include background-size;
   background-color: lightgray;
}


#circles {
   margin-bottom: 20px;

   /* newest standard */
   display: flex;
   flex-flow: row;
   align-items: center;
   justify-content: center;

   /* LEGACY JUNK */
   /* seem to need for Safari */
   display: -webkit-box;
   -webkit-box-orient: horizontal;
   -webkit-box-pack: center;
   -webkit-box-align: center;

   /* Internet Explorer 10 */
   display:-ms-flexbox;
   -ms-flex-pack:center;
   -ms-flex-align:center;
}
